import './app.scss';

window.addEventListener('DOMContentLoaded', function () {
    const domain = `${window.location.origin}/${document.documentElement.lang}/jobs`;
    console.info(
        `%c  ___ _____      _____      _ _      _ _        _  
 | _ \\ __\\ \\    / / __|  __| (_)__ _(_) |_ __ _| | 
 |   / _| \\ \\/\\/ /| _|  / _\` | / _\` | |  _/ _\` | | 
 |_|_\\___| \\_/\\_/ |___| \\__,_|_\\__, |_|\\__\\__,_|_| 
                               |___/               
                                                   `,
        'background: #00195a; color: #d2eb23; font-size:24px;',
        `\n\nconsole.log('Find your new home at REWE digital!');`,
        `\n\nconsole.log('Apply now at ${domain}');`
    );

    const contentUpdateEvent = new CustomEvent('content.loaded', {
        view: window,
        bubbles: true,
        cancelable: false,
        detail: {
            $context: document
        }
    });

    window.dispatchEvent(contentUpdateEvent);

    // Remove no Transitions for all Elements at Start
    setTimeout(() => {
        document.body.classList.remove('has--no-animation');
    }, 1000);

    ObserveVideos();

    PreloadRive();

    EnableImageZoom();

    MobileDetection();

    SetupSliderInputs();
});

function SetupSliderInputs() {
    const sliderFullPrev = document.getElementsByClassName(
        'full-width-slider__swiper-button-prev'
    );

    for (var i = 0; i < sliderFullPrev.length; i++) {
        sliderFullPrev[i].addEventListener('keypress', (e) => {
            if (e.which === 13 || e.which === 32) {
                e.preventDefault();
                e.target.click();
            }
        });
    }

    const sliderFullNext = document.getElementsByClassName(
        'full-width-slider__swiper-button-next'
    );

    for (var j = 0; j < sliderFullNext.length; j++) {
        sliderFullNext[j].addEventListener('keypress', (e) => {
            if (e.which === 13 || e.which === 32) {
                e.preventDefault();
                e.target.click();
            }
        });
    }

    const sliderTextPrev = document.getElementsByClassName(
        'text-image-slider__swiper-button-prev'
    );

    for (var k = 0; k < sliderTextPrev.length; k++) {
        sliderTextPrev[k].addEventListener('keypress', (e) => {
            if (e.which === 13 || e.which === 32) {
                e.preventDefault();
                e.target.click();
            }
        });
    }

    const sliderTextNext = document.getElementsByClassName(
        'text-image-slider__swiper-button-next'
    );

    for (var l = 0; l < sliderTextNext.length; l++) {
        sliderTextNext[l].addEventListener('keypress', (e) => {
            if (e.which === 13 || e.which === 32) {
                e.preventDefault();
                e.target.click();
            }
        });
    }
}

function EnableImageZoom() {
    const zoomableImages = document.querySelectorAll('[data-zoom="true"]');
    zoomableImages.forEach((image) => {
        // eslint-disable-next-line no-undef
        const lightbox = new FsLightbox();
        const imageElement = image.querySelector('picture img');
        if (!imageElement) {
            return;
        }

        const addZoomButton = (container) => {
            if (!container) {
                return;
            }

            const zoomButton = document.createElement('div');
            zoomButton.classList.add('zoom-button');
            zoomButton.classList.add('black');
            zoomButton.tabIndex = 0;

            zoomButton.addEventListener('keypress', (e) => {
                if (e.which === 13 || e.which === 32) {
                    e.preventDefault();
                    e.target.click();
                }
            });

            const zoomIcon = document.createElement('img');
            zoomIcon.src = '/dist/assets/images/lupe.svg';

            zoomButton.appendChild(zoomIcon);

            container.style.position = 'relative';
            container.appendChild(zoomButton);
        };

        const setupImage = (lightbox, image) => {
            lightbox.props.sources = [
                imageElement.dataset.original || imageElement.src
            ];
            lightbox.props.onOpen = (instance) => {
                instance.core.clickZoomer.zoomIn = () => {};
                instance.core.clickZoomer.zoomOut = () => {};

                const closeButton = document.querySelector(
                    ".fslightbox-toolbar-button[title='Close']"
                );
                closeButton.setAttribute('tabindex', 0);

                // Store last focused element to restore focus when modal closes
                const lastFocusedElement = document.activeElement;

                // Focus the close button when modal opens
                closeButton.focus();

                // Handle keyboard events
                const handleKeyboard = (e) => {
                    if (e.key === 'Tab') {
                        e.preventDefault();
                        closeButton.focus();
                    } else if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        closeButton.click();
                    }
                };

                document.addEventListener('keydown', handleKeyboard);

                // Clean up when modal closes
                instance.props.onClose = () => {
                    document.removeEventListener('keydown', handleKeyboard);
                    lastFocusedElement?.focus();
                };
            };

            lightbox.props.captions = [imageElement.title || imageElement.alt];
            image.addEventListener('click', () => {
                lightbox.open();
            });

            console.log(lightbox);
        };

        const container = imageElement?.parentElement?.parentElement;
        addZoomButton(container);

        setupImage(lightbox, image);
    });
}

function PreloadRive() {
    const preloadLink = document.createElement('link');
    preloadLink.rel = 'preload';
    preloadLink.as = 'fetch';
    preloadLink.href =
        'https://unpkg.com/browse/@rive-app/canvas@2.19.3/rive.wasm';
    preloadLink.crossOrigin = 'anonymous';
    document.head.appendChild(preloadLink);
}

function ObserveVideos() {
    // Skip if in pimcore editmode
    if (document.body.classList.contains('pimcore_version_11')) {
        return;
    }

    // Handle Video Play/Pause based on viewport visibility
    const observedVideos = new Map();
    setInterval(() => {
        // console.log('Checking for Videos', observedVideos.size);

        const videoElements = document.querySelectorAll(
            '.pimcore_editable_video iframe'
        );
        videoElements.forEach((videoElement) => {
            if (observedVideos.has(videoElement)) {
                return;
            }

            videoElement.contentWindow.postMessage(
                '{"event":"command","func":"stopVideo","args":""}',
                '*'
            );

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // console.log('Video is in Viewport');
                        videoElement.contentWindow.postMessage(
                            '{"event":"command","func":"playVideo","args":""}',
                            '*'
                        );
                    } else {
                        // console.log('Video is not in Viewport');
                        videoElement.contentWindow.postMessage(
                            '{"event":"command","func":"pauseVideo","args":""}',
                            '*'
                        );
                    }
                });
            });
            observer.observe(videoElement);
            observedVideos.set(videoElement, observer);
        });
    }, 1000);
}

function MobileDetection() {
    const isMobileDeviceOrViewport = function () {
        let checkDevice = false;
        let checkViewport = false;

        // Check Device
        (function (a) {
            if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                    a.substr(0, 4)
                )
            )
                checkDevice = true;
        })(navigator.userAgent || navigator.vendor || window.opera);

        // Check Viewport
        const currentViewportWidth = window.innerWidth;
        if (currentViewportWidth < 768) {
            checkViewport = true;
        }

        if (checkDevice === true) {
            return true;
        }

        return checkViewport === true;
    };

    window.addEventListener('resize', () => {
        window.isMobileDevice = isMobileDeviceOrViewport();
    });
    window.isMobileDevice = isMobileDeviceOrViewport();
}
